import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import SEO from "../../../components/seo/seo";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import List from "../../../components/custom-widgets/list";
import BusinessServicesCards from "../../../components/business-banking/BusinessServicesCards.js";

const AccountingAutomation = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/business-banking/business-services/accounting-automation/hero-accounting-automation-102523-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/business-banking/business-services/accounting-automation/hero-accounting-automation-102523-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/business-banking/business-services/accounting-automation/hero-accounting-automation-102523-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/business-banking/business-services/accounting-automation/hero-accounting-automation-102523-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/business-banking/business-services/accounting-automation/hero-accounting-automation-102523-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/business-banking/business-services/accounting-automation/hero-accounting-automation-102523-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/business-banking/business-services/accounting-automation/hero-accounting-automation-102523-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
      dashboardImage: file(relativePath: { eq: "finsync-accounting-services.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  `);

  const heroChevronData = {
    id: "accounting-automation-hero",
    ...getHeroImgVariables(imgData),
    altText: "Business owner doing accounting in his shop.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Utilize automation for advanced controls over your business' finances"
          },
          button: {
            id: "accounting-automation-hero-cta",
            text: "Automate Your Accounting",
            url: "https://www.finsync.com/wafdbank/",
            externalLink: true,
            class: "btn-white",
            target: "blank",
            style: {
              minWidth: "fit-content"
            }
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/business-banking/small-business",
      title: "Business Banking"
    },
    {
      id: 2,
      url: "/business-banking/business-services",
      title: "Business Services"
    },
    {
      id: 3,
      active: true,
      title: "Accounting Automation"
    }
  ];

  const SEOData = {
    title: "Accounting Process Automation - FINSYNC",
    meta: [
      {
        name: "title",
        property: "og:title",
        content: "Accounting Process Automation - FINSYNC"
      },
      {
        name: "description",
        property: "og:description",
        content:
          "With FINSYNC and WaFd Bank, you can harness the power of automation to simplify your accounting processes and gain better insights into your business finances."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/business-banking/business-services/accounting-automation"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-accounting-automation-102523.jpg"
      }
    ]
  };

  const benefitsOfFinsyncListData = {
    greenCircleCheckmark: true,
    accountName: "finsync-benefits-list",
    items: [
      {
        id: 1,
        text: "User-friendly platform to seamlessly bridge your accounting with your business operations"
      },
      {
        id: 2,
        text: "Take the guesswork out of accounting with clear and concise workflows"
      },
      {
        id: 3,
        text: "Make single or recurring journal entries"
      },
      {
        id: 4,
        text: "Customizable chart of accounts"
      },
      {
        id: 5,
        text: "Track income and expenses by department, location, and associate"
      },
      {
        id: 6,
        text: "Cash and accrual reports"
      },
      {
        id: 7,
        text: "Robust documentation retention to save you time hunting for files"
      },
      {
        id: 8,
        text: "Transaction reports, audit trail, and more"
      }
    ]
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Accounting Automation</h1>
        <div className="row">
          <div className="col-md-6">
            <h4>
              Get back to doing what you love, like heading to your kid's tournaments and helping your business grow.
              With FINSYNC, you can harness the power of automation to simplify your accounting processes and gain
              better insights into your business finances.
            </h4>
            <h4 className="text-success font-weight-semi-bold">Benefits of FINSYNC:</h4>
            <List {...benefitsOfFinsyncListData} />
          </div>
          <div className="col-md-6">
            <GatsbyImage
              image={imgData.dashboardImage.childImageSharp.gatsbyImageData}
              alt="Finsync accounting services dashboard"
            />
          </div>
        </div>
      </section>
      <section className="bg-green-60">
        <div className="container">
          <div className="d-lg-flex align-items-center">
            <h4 className="text-white mb-lg-0 font-weight-bold">
              As a client of WaFd Bank, join the FINSYNC network for free!
            </h4>
            <a
              className="btn-white ml-lg-4 btn w-100 w-sm-auto"
              id="claim-your-savings-finsync-cta"
              href="https://www.finsync.com/wafdbank/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Claim your savings today
            </a>
          </div>
        </div>
      </section>
      <section className="container">
        <BusinessServicesCards servicesArray={[4, 5]} />
      </section>
    </SecondaryLanding>
  );
};

export default AccountingAutomation;
